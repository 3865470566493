import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {WidgetService} from '../../services/widget.service';
import {BsLocaleService} from 'ngx-bootstrap';
import {fade, toggleFade} from '../../animations';
import {Hotel} from '../../../shared/models/hotel';
import {FilterComponent} from '../filter/filter.component';

@Component({
  selector: 'app-filter-offer',
  templateUrl: '../filter/filter.component.html',
  styleUrls: ['../filter/filter.component.scss'],
  animations: [
    fade,
    toggleFade
  ]
})
export class FilterOfferComponent extends FilterComponent implements OnInit {

  today: Date;
  reservationRestriction: Date;
  persons = false;
  _hotel: Hotel;
  @Output('update') change: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    protected widgetService: WidgetService,
    protected localeService: BsLocaleService
  ) {
    super(widgetService, localeService);
  }

  onValueChange(event) {
    this.widgetService.setBookingDates(event[0], event[1]);
    this.change.emit('datesUpdate');
  }

  onChildrenChange(event) {
    this.widgetService.setChildren(event.target.value);
    this.change.emit('datesUpdate');
  }

  onAdultsChange(event) {
    this.widgetService.setAdults(event.target.value);
    this.change.emit('datesUpdate');
  }

  onRemoveAdult() {
    this.widgetService.setAdults(this.booking.adults === 1 ? 1 : --this.booking.adults);
    this.change.emit('datesUpdate');
  }

  onAddAdult() {
    this.widgetService.setAdults(++this.booking.adults);
    this.change.emit('datesUpdate');
  }

  onRemoveChild() {
    this.widgetService.setChildren(this.booking.children.count === 0 ? 0 : --this.booking.children.count);
    this.change.emit('datesUpdate');
  }

  onAddChild() {
    this.widgetService.setChildren(this.booking.children.count === this.hotel.configuration.children_count ? this.booking.children.count : ++this.booking.children.count);
    this.change.emit('datesUpdate');
  }
}
