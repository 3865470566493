import { Component, OnInit } from '@angular/core';
import {WidgetService} from '../../services/widget.service';
import {Hotel} from '../../../shared/models/hotel';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {
  hotel: Hotel;

  constructor(
    private widgetService: WidgetService
  ) { }

  ngOnInit() {
    this.hotel = this.widgetService.getHotel();
  }

}
