import {Component, OnInit} from '@angular/core';
import {WidgetService} from '../../services/widget.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private widgetService: WidgetService,
    private router: Router
  ) {
  }

  ngOnInit() {
    const hash = this.route.snapshot.queryParamMap.get('hash');
    const view = this.route.snapshot.queryParamMap.get('view');
    const query = Object.assign({}, this.route.snapshot.queryParams);

    if (!hash) {
      this.router.navigate(['/error']);
    }

    delete query.hash;

    this.widgetService.verifyHash(hash)
      .subscribe(
        (result: any) => {
          const status = parseInt(result.message, 10);

          if (status === 0) {
            this.widgetService.setHotel(hash);

            switch (view) {
              case 'packages' :
                this.router.navigate(['/packages'], {
                  queryParams: query
                });
                break;
              default :
                this.router.navigate(['/'], {
                  queryParams: query
                });
            }
          } else {
            this.router.navigate(['/error']);
          }
        },
      );
  }
}
