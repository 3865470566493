import {Injectable, Inject} from '@angular/core';
import {APP_CONFIG, AppConfig} from '../../app.config';
import {HttpClient} from '@angular/common/http';
import {RoomGroup} from '../../shared/models/room-group';
import {Hotel} from '../../shared/models/hotel';
import {map, finalize} from 'rxjs/operators';
import {Booking} from '../../shared/models/booking';
import {dateToString} from '../../shared/helpers';
import {Subject, BehaviorSubject} from 'rxjs';
import * as moment from 'moment';
import {Package} from '../../shared/models/package';

interface BookingDates {
  dateFrom: string;
  dateTo: string;
}

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  private readonly _url = `${this.config.api}/booking-engine`;
  private _hash: string;
  private _paymentUrl: string;
  private _hotel: Hotel;
  private _booking: Booking;
  private _bookingDatesChange = new Subject<BookingDates>();
  public bookingDatesChange$ = this._bookingDatesChange.asObservable();
  private _bedsChange = new Subject<void>();
  public bedsChange$ = this._bedsChange.asObservable();
  private _requesting = new BehaviorSubject<boolean>(false);
  public requesting$ = this._requesting.asObservable();

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
  ) {
    this.setDefaultBooking();
  }

  verifyHash(hash: string) {
    this._requesting.next(true);

    return this.http.post(`${this._url}/verify`, {hash})
      .pipe(
        finalize(() => this._requesting.next(false))
      );
  }

  hash() {
    return this._hash;
  }

  paymentUrl() {
    return this._paymentUrl;
  }

  paymentExpires() {
    return this._hotel.configuration.time_for_complete_payment;
  }

  setHotel(hash: string) {
    this._hash = hash;
  }

  getHotel() {
    return this._hotel;
  }

  getBooking() {
    return this._booking;
  }

  getRoom() {
    return this._booking.room_group;
  }

  setDefaultBooking() {
    this._booking = new Booking();
    this._booking.date_from = moment().add(1, 'day').format('YYYY-MM-DD');
    this._booking.date_to = moment().add(2, 'days').format('YYYY-MM-DD');
    this._booking.adults = 2;
    this._booking.children.count = 0;
    this._booking.payment_method = 100;
    this._booking.room_group = new RoomGroup();
  }

  hotel() {
    return this.http.post<{ data: Hotel }>(`${this._url}/hotel`, {}).pipe(
      map(response => {
        this._hotel = response.data;

        return response;
      })
    );
  }

  previewPackage(packageModel: Package) {
    return this.http.post<{ data: Package }>(`${this._url}/package`, {package: packageModel});
  }

  previewRoom(room: RoomGroup) {
    return this.http.post<{ data: RoomGroup }>(`${this._url}/room`, {room_group: room});
  }

  checkRooms() {
    this._requesting.next(true);

    return this.http.post<{ data: RoomGroup[] }>(`${this._url}/check-rooms`, {
      date_from: this._booking.date_from,
      date_to: this._booking.date_to,
      adults: this._booking.adults,
      children: this._booking.children,
    }).pipe(
      finalize(() => this._requesting.next(false))
    );
  }

  packages() {
    this._requesting.next(true);

    return this.http.post<{ data: Package[] }>(`${this._url}/packages`, {
      date_from: this._booking.date_from,
      date_to: this._booking.date_to,
      adults: this._booking.adults,
      children: this._booking.children,
    }).pipe(
      finalize(() => this._requesting.next(false))
    );
  }

  book() {
    this._requesting.next(true);

    return this.http.post<any>(`${this._url}/book`, this._booking)
      .pipe(
        finalize(() => this._requesting.next(false))
      );
  }

  setRoom(room: RoomGroup) {
    this._booking.room_group = room;
  }

  setPackages(offer: Package) {
    this._booking.package = offer;
  }

  setPaymentUrl(url: string) {
    this._paymentUrl = url;
  }

  refresh() {
    this.setDefaultBooking();
    this._hotel = null;
  }

  setBookingDates(from: string | Date, to: string | Date) {
    let formattedFrom: string;
    let formattedTo: string;

    formattedFrom = typeof from === 'string' ? from : dateToString(from);
    formattedTo = typeof to === 'string' ? to : dateToString(to);

    if (moment(formattedFrom).diff(formattedTo) > 0) {
      this._booking.date_from = moment().format('YYYY-MM-DD');
      this._booking.date_to = moment().add(1, 'days').format('YYYY-MM-DD');
    } else {
      this._booking.date_from = formattedFrom;
      this._booking.date_to = formattedTo;
    }

    this._bookingDatesChange.next({dateFrom: formattedFrom, dateTo: formattedTo});
  }

  setAdults(count: number) {
    this._booking.adults = count <= 1 ? 1 : count;

    this._bedsChange.next();
  }

  setChildren(count: number) {
    this._booking.children.count = count <= 0 ? 0 : Math.min(count, this._hotel.configuration.children_count);

    this._booking.children.age = this._booking.children.age.slice(0, count);

    this._bedsChange.next();
  }

  bedsChanged() {
    this._bedsChange.next();
  }

  isBellowMinimumDays(minimumDays = 0) {
    return moment(this._booking.date_to).diff(moment(this._booking.date_from), 'days') < minimumDays;
  }
}
