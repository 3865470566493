import {Component, OnDestroy, OnInit} from '@angular/core';
import {fade, fadeIn, toggleFade} from '../../animations';
import {WidgetService} from '../../services/widget.service';
import {RoomGroup} from '../../../shared/models/room-group';
import {Hotel} from '../../../shared/models/hotel';
import {ActivatedRoute, Router} from '@angular/router';
import {merge, Subscription} from 'rxjs';
import {Package} from '../../../shared/models/package';
import * as moment from 'moment';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss'],
  animations: [
    fade,
    fadeIn,
    toggleFade
  ]
})
export class RoomsComponent implements OnInit, OnDestroy {

  constructor(
    private widgetService: WidgetService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  rooms: RoomGroup[] = [];
  hotel: Hotel;
  preview: RoomGroup;

  previewRoomMode = false;
  showRooms: any;
  private bookingChanges: Subscription;
  calendarScope: Date[];
  checkin: string;
  checkout: string;

  ngOnInit() {
    this.getHotelInfo();
    this.registerInitialBooking();
    this.getRoomGroups();
    this.registerBookingChanges();
  }

  ngOnDestroy() {
    this.bookingChanges.unsubscribe();
  }

  registerBookingChanges() {
    if (!this.bookingChanges) {
      this.bookingChanges = merge(this.widgetService.bookingDatesChange$, this.widgetService.bedsChange$)
        .subscribe(() => {
          this.getRoomGroups();
        });
    }
  }

  registerInitialBooking() {
    this.checkin = this.route.snapshot.queryParamMap.get('checkin');
    this.checkout = this.route.snapshot.queryParamMap.get('checkout');
    const guests = parseInt(this.route.snapshot.queryParamMap.get('guests'), 10);

    if (this.checkin && this.checkout) {
      this.calendarScope = [moment(this.checkin).toDate(), moment(this.checkout).toDate()];
      this.widgetService.setBookingDates(this.checkin, this.checkout);
    }

    if (guests > 0) {
      this.widgetService.setAdults(guests);
    }
  }

  getRoomGroups() {
    this.widgetService.checkRooms().subscribe((response) => {
      this.rooms = response.data;
    });
  }

  getHotelInfo() {
    this.hotel = this.widgetService.getHotel();

    if (!this.hotel) {
      this.widgetService.hotel()
        .subscribe(hotel => {
          this.hotel = hotel.data;
          if (!(this.checkin && this.checkin)) {
            this.calendarScope = [moment().add(1, 'days').toDate(), moment().add(this.hotel.configuration.min_stay + 1, 'days').toDate()];
            this.widgetService.setBookingDates(this.calendarScope[0], this.calendarScope[1]);
          }
        });
    }
  }

  goToConfirmation(room: RoomGroup) {
    this.widgetService.setRoom(room);
    this.widgetService.setPackages(new Package());

    this.router.navigate(['/confirmation']);
  }

  previewRoom(room: RoomGroup) {
    this.previewRoomMode = true;

    this.widgetService.previewRoom(room)
      .subscribe(result => this.preview = result.data);
  }

  onPreviewClose() {
    this.previewRoomMode = false;
    this.preview = null;
  }

  isBellowMinimumDays() {
    if (this.hotel) {
      return this.widgetService.isBellowMinimumDays(this.hotel.configuration.min_stay);
    }

    return false;
  }
}
