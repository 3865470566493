import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {WidgetService} from '../../services/widget.service';
import {BsLocaleService} from 'ngx-bootstrap';
import {fade, toggleFade} from '../../animations';
import {Hotel} from '../../../shared/models/hotel';
import * as moment from 'moment';
import {BsDaterangepickerConfig} from 'ngx-bootstrap/datepicker/bs-daterangepicker.config';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  animations: [
    fade,
    toggleFade
  ]
})
export class FilterComponent implements OnInit, OnChanges {

  today: Date;
  reservationRestriction: Date;
  isPeopleMenuOpen = false;
  _hotel: Hotel;
  bsConfig: Partial<BsDaterangepickerConfig> = {
    showWeekNumbers: false,
    containerClass: 'theme-default'
  };
  @Input('onDatesChange') bsValue: Date[];
  dateRange: Date[];

  constructor(
    protected widgetService: WidgetService,
    protected localeService: BsLocaleService,
  ) {
    this.bsValue = [moment(widgetService.getBooking().date_from).toDate(), moment(widgetService.getBooking().date_to).toDate()];
  }

  ngOnInit() {
    this.localeService.use('pl');
    this.setDefaults();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.bsValue = changes.bsValue.currentValue;
  }

  setDefaults() {
    this.setFutureReservationRestriction();
    this.today = moment().toDate();
  }

  setFutureReservationRestriction() {
    this.reservationRestriction = new Date();
    this._hotel = this.widgetService.getHotel();

    if (!this._hotel) {
      this.widgetService.hotel()
        .subscribe(hotel => this.reservationRestriction.setMonth(this.reservationRestriction.getMonth() + hotel.data.configuration.future_reservation_restriction));
    } else {
      this.reservationRestriction.setMonth(this.reservationRestriction.getMonth() + this._hotel.configuration.future_reservation_restriction);
    }
  }

  onValueChange(event) {
    console.log(this.dateRange);
    this.widgetService.setBookingDates(event[0], event[1]);
  }

  onChildrenChange(event) {
    this.widgetService.setChildren(event.target.value);
  }

  onAdultsChange(event) {
    this.widgetService.setAdults(event.target.value);
  }

  onRemoveAdult() {
    this.widgetService.setAdults(this.booking.adults === 1 ? 1 : --this.booking.adults);
  }

  onAddAdult() {
    this.widgetService.setAdults(++this.booking.adults);
  }

  onRemoveChild() {
    this.widgetService.setChildren(this.booking.children.count === 0 ? 0 : --this.booking.children.count);
  }

  onAddChild() {
    this.widgetService.setChildren(this.booking.children.count === this.hotel.configuration.children_count ? this.booking.children.count : ++this.booking.children.count);
  }

  onAgeChange() {
    this.widgetService.bedsChanged();
  }

  get hotel() {
    return this.widgetService.getHotel();
  }

  get booking() {
    return this.widgetService.getBooking();
  }

  get childrenCount() {
    return Array(Math.min(this.booking.children.count, this.hotel.configuration.children_count)).fill(0).map((x, i) => i);
  }

  togglePeopleMenu() {
    this.isPeopleMenuOpen = !this.isPeopleMenuOpen;
  }

  outerPeopleMenu() {
    if (this.isPeopleMenuOpen) {
      this.isPeopleMenuOpen = !this.isPeopleMenuOpen;
    }
  }

  firstDateSelected($event: MouseEvent) {
    console.log($event);
  }
}
