import {AfterViewInit, Component, OnInit} from '@angular/core';
import {fade, fadeIn, toggleFade} from '../../animations';
import {WidgetService} from '../../services/widget.service';
import {Router} from '@angular/router';
import {Package} from '../../../shared/models/package';
import {RoomGroup} from '../../../shared/models/room-group';
import {Hotel} from '../../../shared/models/hotel';
import * as moment from 'moment';

class Preview {
  id: number;
  preview: boolean;

  constructor(id: number, preview: boolean) {
    this.id = id;
    this.preview = preview;
  }
}

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss'],
  animations: [
    fade,
    fadeIn,
    toggleFade
  ]
})
export class PackagesComponent implements OnInit, AfterViewInit {
  packages: Package[];

  rooms: RoomGroup[] = [];

  hotel: Hotel;
  previewOffer: Preview[] = [];
  offerRoomsList = [];

  previewMode = false;
  showOffers: any;
  AvailabilityEnum = {
    CHANGE_DATES: 1,
    CHANGE_STRICT_DATES: 2
  };

  showRooms: any;
  previewPackage: Package;
  previewPackageMode: boolean;
  previewRoomGroup: RoomGroup;
  previewRoomMode: boolean;

  calendarScope: Date[];

  constructor(
    private widgetService: WidgetService,
    private router: Router) {
  }

  ngOnInit() {
    this.getHotelInfo();
    this.getPackages();
  }

  ngAfterViewInit(): void {
    if (!this.hotel.configuration.packages) {
      this.router.navigate(['/']);
    }
  }

  getPackages() {
    this.widgetService.packages().subscribe(response => {
      this.packages = response.data;
      for (const offer of this.packages) {
        this.offerRoomsList[offer.id] = false;
        this.offerAvailable(offer);
      }
    });
  }

  getHotelInfo() {
    this.hotel = this.widgetService.getHotel();

    if (!this.hotel) {
      this.widgetService.hotel()
        .subscribe(hotel => this.hotel = hotel.data);
    }
  }

  toggleOfferRoomsList(id: number) {
    this.offerRoomsList.forEach((list, index) => {
      this.offerRoomsList[index] = index === id;
    });
  }

  goToConfirmation(room: RoomGroup, offer: Package) {
    this.widgetService.setRoom(room);
    this.widgetService.setPackages(offer);

    this.router.navigate(['/confirmation']);
  }

  offerAvailable(specialOffer: Package) {
    const scopeFrom = moment(this.widgetService.getBooking().date_from).startOf('day');
    const scopeTo = moment(this.widgetService.getBooking().date_to).startOf('day');
    const availableFrom = moment(specialOffer.available_from).startOf('day');
    const availableTo = moment(specialOffer.available_to).startOf('day');
    const daysToStartOffer = availableFrom.diff(scopeFrom, 'day');
    const daysToEndOffer = availableTo.diff(scopeTo, 'day');

    if (specialOffer.strict_dates) {
      if (scopeFrom.format('DD-MM-YYYY') !== availableFrom.format('DD-MM-YYYY') || scopeTo.format('DD-MM-YYYY') !== availableTo.format('DD-MM-YYYY')) {
        specialOffer.availability_status = this.AvailabilityEnum.CHANGE_STRICT_DATES;
        return;
      }

      specialOffer.availability_status = 0;
      return;
    }

    if (daysToStartOffer > 0 && daysToEndOffer >= 0 || daysToStartOffer <= 0 && daysToEndOffer < 0) {
      specialOffer.availability_status = this.AvailabilityEnum.CHANGE_DATES;
    }
  }

  setDates(specialOffer: Package) {
    if (specialOffer.strict_dates) {
      this.calendarScope = [moment(specialOffer.available_from).toDate(), moment(specialOffer.available_to).toDate()];
    } else {
      this.calendarScope = [moment(specialOffer.available_from).toDate(), moment(specialOffer.available_from).add(1, 'day').toDate()];
    }
    this.widgetService.setBookingDates(this.calendarScope[0], this.calendarScope[1]);

    this.getPackages();
  }

  previewPackageDialog(packageModel: Package) {
    this.previewPackageMode = true;

    this.widgetService.previewPackage(packageModel)
      .subscribe(result => this.previewPackage = result.data);
  }

  previewRoomDialog(room: RoomGroup) {
    this.previewRoomMode = true;

    this.widgetService.previewRoom(room)
      .subscribe(result => this.previewRoomGroup = result.data);
  }

  onPreviewClose() {
    this.previewRoomMode = false;
    this.previewPackageMode = false;
    this.previewRoomGroup = null;
    this.previewPackage = null;
  }
}
